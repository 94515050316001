import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

window.addEventListener('load', () => {
  gsap.to(".hero", {
    opacity: '0',
    duration: 2,
    ease: "slow",
    scrollTrigger: {
      trigger: ".hero",
      start: "bottom 80%",
      end: "bottom top",
      scrub: 1.5,
    },
  });
});
